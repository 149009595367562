<template>
  <div class="orderStatus">
    <div style="font-weight: 700">订单状态：</div>
    <div style="color: red;">
      {{
        detailInfo.orderStatus == 1 ? '已完成' :
            detailInfo.orderStatus == 0 ? '待确认' : detailInfo.orderStatus == 3 ? '待完成'
                : detailInfo.orderStatus == 4 ? '待评价'
                    : detailInfo.orderStatus < 0 ? '已取消' : ''
      }}
    </div>
  </div>
  <div class="orderInfo">
    <div class="orderItem">
      <div class="left">工人姓名</div>
      <div class="right">{{ detailInfo.name }}</div>
    </div>
    <div class="orderItem">
      <div class="left">工人编号</div>
      <div class="right">{{ detailInfo.workerNo }}</div>
    </div>

  </div>

  <div class="orderInfo">
    <div class="orderItem">
      <div class="left">订单编号</div>
      <div class="right">
        <span>{{ detailInfo.orderId }}</span>
        <span @click="copy(detailInfo.orderId)" style="color: #4DBAFD;margin-left: 10px">复制</span>

      </div>
    </div>
    <div class="orderItem">
      <div class="left">下单时间</div>
      <div class="right">{{ detailInfo.createTime }}</div>
    </div>

  </div>

  <div class="orderInfo">
    <div class="orderItem">
      <div class="left">工人级别</div>
      <div class="right">{{ detailInfo.workerLevel }}</div>
    </div>
    <div class="orderItem">
      <div class="left">服务内容</div>
      <div class="right">{{ detailInfo.workName }}</div>
    </div>
    <div class="orderItem">
      <div class="left">服务时间</div>
      <div class="right">{{ detailInfo.workTime }}</div>
    </div>
    <div class="orderItem">
      <div class="left">服务参考价格</div>
      <div class="right">{{ detailInfo.referencePrice }}</div>
    </div>
  </div>


  <OrderDetailTag :order-info="detailInfo"></OrderDetailTag>

</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API from "../../api/Order";
import req from "../../request/request";
import router from "../../router";
import {useRoute} from "vue-router";
import {Dialog} from "vant/lib/";
import API_ORDER from "@/api/Order";
import OrderDetailTag from "@/components/common/OrderDetailCompanyTag";
import {Toast} from "vant";
import useClipboard from 'vue-clipboard3'


export default {
  name: 'OrderDetailCompanyCard',
  components: {OrderDetailTag},
  props: {
    detailInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    const data = reactive({
          detailInfo: '',
          buttonList: [
            {name: ''}
          ]
        },
    )
    const {toClipboard} = useClipboard()
    const methodsMap = {


      copy: async (value) => {
        try {
          console.log(value)
          await toClipboard(value)
          Toast("已复制到剪贴板！")
        } catch (e) {
          console.error(e)
        }
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>

.orderStatus {
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 10px;
}

.orderInfo {
  border-radius: 6px;
  margin-top: 8px;
  background-color: #FFFFFF;


  .orderItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .right {
      font-size: 14px;
      color: gray;
    }

    .left {
      //font-size: 16px;
    }

  }
}

</style>
