<template>
  <div class="bottonFix">
    <div class="bottonFixItem">
      <!--    <van-button round style="width: 80%;" size="mini" plain type="default" class="bottonFixItemSingle"-->
      <!--                @click="getAxb(orderInfo)">联系单位-->
      <!--    </van-button>-->
      <!--    <van-button v-show="orderInfo.orderStatus >=0 && orderInfo.orderStatus!=1" round style="width: 80%;" size="mini"-->
      <!--                plain type="default" class="bottonFixItemSingle" @click="cancelOrder(orderInfo)">取消订单-->
      <!--    </van-button>-->
      <div>
        <van-button round size="small" plain type="default" class="bottonFixItemSingle" @click="getAxb(orderInfo)">联系单位
        </van-button>
      </div>
      <div>
        <van-button v-show="orderInfo.orderStatus>=0 && orderInfo.orderStatus != 1" round size="small" plain
                    type="default" class="bottonFixItemSingle" @click="cancelOrder(orderInfo)">
          取消订单
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import {Dialog} from "vant";
import API_ORDER from "../../api/OrderReq";
import API_USER from "../../api/UserReq";
import router from "@/router";

export default {
  name: 'orderDetailWorkerTag',
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {

    const data = reactive({},
    )
    let methodMap = {

      getAxb: (item) => {
        let reqJson = {
          "companyUid": item.companyUid,
          "workerUid": JSON.parse(localStorage.getItem("userinfo")).uid,
        }
        API_USER.getSecretNo(reqJson)
      },
      cancelOrder: (item) => {

        router.push("/cancelOrder/" + item.orderId)

        // Dialog.confirm({
        //   title: '取消订单',
        //   message: '请确认是否取消订单？',
        // }).then(() => {
        //   let json = {
        //     status: -3,
        //     orderId: item.orderId
        //   }
        //   API_ORDER.handle(json)
        //
        // }).catch(() => {
        //   // on cancel
        // });
      },
    }


    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less">

.bottonFix {

  position: fixed;
  bottom: 0px;
  background-color: #FFFFFF;
  width: 100%;
  height: 48px;
  text-align: center;
  line-height: 48px;

  &Item {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-left: 10px;

    &Single {
      --van-button-default-border-color: #6F6E6E;
      vertical-align: middle;
    }

  }
}

</style>