<template>
  <div class="register-select app_content">
    <div class="warp_body">
      <order-detail-company-card :detail-info="detailInfo"
                                 v-if="userType.toUpperCase()=='C'"></order-detail-company-card>
      <order-detail-worker-card :detail-info="detailInfo"
                                v-else-if="userType.toUpperCase()=='P'"></order-detail-worker-card>


    </div>

  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API from "../api/Order";
import req from "../request/request";
import router from "../router";
import {useRoute} from "vue-router";
import {Dialog} from "vant/lib/";
import API_ORDER from "@/api/Order";
import OrderDetailTag from "@/components/common/OrderDetailCompanyTag";
import OrderDetailCompanyCard from "@/components/common/OrderDetailCompanyCard";
import OrderDetailWorkerCard from "@/components/common/OrderDetailWorkerCard";


export default {
  name: 'OrderDetail',
  components: {OrderDetailWorkerCard, OrderDetailCompanyCard, OrderDetailTag},
  setup() {
    onBeforeMount(() => {
      const route = useRoute();
      let orderId = route.params.orderId
      data.userType = JSON.parse(localStorage.getItem('userinfo')).userType
      methodsMap.getOrderInfo(orderId)
    });
    const data = reactive({
          detailInfo: '',
          userType: ''
        },
    )

    const methodsMap = {
      getOrderInfo: (orderId) => {
        req.request(API.orderDetail + orderId, null, {method: 'GET'}).then((res) => {
          data.detailInfo = res.data.data
        })
      },
      req: (reqJson) => {
        req.request(API_ORDER.handle, reqJson, {method: 'POST'}, {message: '处理中...'})
            .then((res) => {
              if (res.data.code == 200) {
                router.go(-1)
              }
            })
      },
      confirm: (item) => {
        data.showEvaluate = false
        if (item.orderStatus == 0) {
          Dialog.confirm({
            title: '订单',
            message: '是否确认到达？',
          }).then(() => {
            let json = {
              status: 3,
              orderId: item.orderId
            }
            methodsMap.req(json)

          }).catch(() => {
            // on cancel
          });
        } else if (item.orderStatus == 3) {
          Dialog.confirm({
            title: '订单',
            message: '是否确认完成？',
          }).then(() => {
            let json = {
              status: 4,
              orderId: item.orderId
            }
            methodsMap.req(json)
          }).catch(() => {
            // on cancel
          });

        } else if (item.orderStatus == 4) {
          data.showEvaluate = true

        }
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {
    .orderStatus {
      display: flex;
      justify-content: space-between;
      background-color: #FFFFFF;
      padding: 10px;
    }

    .orderInfo {
      border-radius: 6px;
      margin-top: 8px;
      background-color: #FFFFFF;


      .orderItem {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        .right {
          font-size: 14px;
          color: gray;
        }

      }
    }
  }
}
</style>
