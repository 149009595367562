<template>
  <div class="bottonFix">

    <div class="bottonFixItem">
      <div>
        <van-button round size="small" plain type="default" class="bottonFixItemSingle" @click="getAxb(orderInfo)">联系工人
        </van-button>
      </div>
      <div>
        <van-button v-show="orderInfo.orderStatus>=0 && orderInfo.orderStatus != 1" round size="small" plain
                    type="default" class="bottonFixItemSingle" @click="cancelOrder(orderInfo)">
          取消订单
        </van-button>
      </div>
      <div>
        <van-button v-show="orderInfo.orderStatus>=0 && orderInfo.orderStatus != 1" round  plain size="small " color="#4DBAFD"
                    type="default"
                    class="bottonFixItemSingle" @click="confirm(orderInfo)">{{
            orderInfo.orderStatus == 0 ? '确认到达' : orderInfo.orderStatus == 3 ? '确认完成'
                : orderInfo.orderStatus == 4 ? '去评价' : ''
          }}
        </van-button>
      </div>
    </div>

  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import {Dialog} from "vant";
import API_ORDER from "../../api/OrderReq";
import API_USER from "../../api/UserReq";
import router from "@/router";

export default {
  name: 'orderDetailTag',
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {

    const data = reactive({
          detailInfo: '',
          buttonList: [
            {name: ''}
          ]
        },
    )
    let methodMap = {

      getAxb: (item) => {
        let reqJson = {
          "companyUid": JSON.parse(localStorage.getItem("userinfo")).uid,
          "workerUid": item.uid,
        }
        API_USER.getSecretNo(reqJson)
      },
      cancelOrder: (item) => {

        router.push("/cancelOrder/" + item.orderId)

        // Dialog.confirm({
        //   title: '取消订单',
        //   message: '请确认是否取消订单？',
        // }).then(() => {
        //   let json = {
        //     status: -2,
        //     orderId: item.orderId
        //   }
        //   API_ORDER.handle(json)
        //
        // }).catch(() => {
        //   // on cancel
        // });
      },

      // 右边返回
      confirm: (item) => {
        if (item.orderStatus == 0) {
          Dialog.confirm({
            title: '订单',
            message: '是否确认到达？',
          }).then(() => {
            let json = {
              status: 3,
              orderId: item.orderId
            }
            API_ORDER.handle(json, "/OrderList")

          }).catch(() => {
            // on cancel
          });
        } else if (item.orderStatus == 3) {
          Dialog.confirm({
            title: '订单',
            message: '是否确认完成？',
          }).then(() => {
            let json = {
              status: 4,
              orderId: item.orderId
            }
            API_ORDER.handle(json, "/OrderList")
          }).catch(() => {
            // on cancel
          });

        } else if (item.orderStatus == 4) {

          router.push("/OrderEvaluate/" + item.orderId)
        }
      }
    }


    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less">

.bottonFix {

  position: fixed;
  bottom: 0px;
  background-color: #FFFFFF;
  width: 100%;
  height: 48px;
  text-align: center;
  line-height: 48px;

  &Item {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-left: 10px;

    &Single {
      --van-button-default-border-color: #6F6E6E;
      vertical-align: middle;
    }

  }
}

</style>